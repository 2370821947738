import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Grid,
  makeStyles,
  StandardProps,
  Theme,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import { StyleClassKey } from "JS/Typescript";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.grey["900"],
    color: theme.palette.common.white,
  },
  bolderText: {
    fontWeight: "bolder",
  },
  heading: {
    marginBottom: theme.spacing(1),
  },
  contentContainer: {
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3, 5),
    },
  },
  mediaIconAvatar: {
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    cursor: "pointer",
    margin: theme.spacing(0, 1),
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  contactInfoContainer: {
    display: "flex",
    flexDirection: "column",
  },

  contactInfo: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
    },
  },

  gridItem: {
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
    },
  },
}));

export type FooterClassKey = StyleClassKey<typeof useStyles>;

export interface FooterProps
  extends StandardProps<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    FooterClassKey
  > {}
export const Footer = (props: FooterProps) => {
  const classes = useStyles(props);

  return (
    <section className={classes.root}>
      <Grid container className={classes.contentContainer}>
        <Grid item lg={3}></Grid>
        <Grid item lg={2} md={12} className={classes.gridItem}>
          <Typography
            className={clsx(classes.bolderText, classes.heading)}
            variant="h6"
          >
            About ALTLAS
          </Typography>
          <Typography variant="body1">
            Activity tracker provides Altitude monitoring,
            track information, record your activities with GPS navigation and
            planning tools.
          </Typography>
        </Grid>
        <Grid item lg={2} md={12} className={classes.gridItem}>
          <Typography
            className={clsx(classes.bolderText, classes.heading)}
            variant="h6"
          >
            Links
          </Typography>
          <Typography
              style={{
                cursor: "pointer",
              }}
              className={classes.bolderText}
              variant="body1"
              onClick={() => window.open("https://tvaknin.github.io/privacy_policy")}
            >
              Privacy Policy
            </Typography>
        </Grid>
        <Grid
          item
          lg={2}
          md={12}
          className={clsx(classes.contactInfoContainer, classes.gridItem)}
        >
          <div
            style={{
              display: "flex",
            }}
          >
            <Avatar
              className={classes.mediaIconAvatar}
              onClick={() =>
                window.open(
                  "https://www.facebook.com/Altlas-App-113487803675588/",
                  "_blank"
                )
              }
            >
              <FontAwesomeIcon icon={["fab", "facebook-f"]} />
            </Avatar>
            <Avatar
              className={classes.mediaIconAvatar}
              onClick={() =>
                window.open(
                  "https://t.me/ALTLASAPP",
                  "_blank"
                )
              }
            >
              <FontAwesomeIcon icon={["fab", "telegram"]} />
            </Avatar>
            <Avatar
              className={classes.mediaIconAvatar}
              onClick={() =>
                window.open("https://www.instagram.com/erol1apps/", "_blank")
              }
            >
              <FontAwesomeIcon icon={["fab", "instagram"]} />
            </Avatar>
          </div>
          <div className={classes.contactInfo}>
            <Typography variant="body1">
              We would love to hear from you
            </Typography>
            <Typography
              style={{
                cursor: "pointer",
              }}
              className={classes.bolderText}
              variant="body1"
              onClick={() => window.open("mailto:erol1apps@gmail.com")}
            >
              erol1apps@gmail.com
            </Typography>
          </div>
        </Grid>
        <Grid item lg={3}></Grid>
      </Grid>
    </section>
  );
};
