import { makeStyles, StandardProps, Theme } from "@material-ui/core";
import { getCordinates } from "JS/Helpers/Helpers";
import { mapView } from "JS/Helpers/Media";
import { useGeoPoints, useGeoPointsIndex } from "JS/React/Hooks/Trails";
import { StyleClassKey } from "JS/Typescript";
import React from "react";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  LayersControl,
  Polyline,
  Circle,
  useMap
} from "react-leaflet";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));


export type MapSectionClassKey = StyleClassKey<typeof useStyles>;

export interface MapSectionProps
  extends StandardProps<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
    MapSectionClassKey
  > {}
  
export const MapSection = (props: MapSectionProps) => {
  const classes = useStyles(props);
  const { className, ...rest } = props;
  const { points: allPoints } = useGeoPointsIndex('GLOBAL');
  const { points, refetch} = useGeoPoints();
  
    return (
      <div
        style={{
          width: "85vw",
        }}
      >
        <MapContainer
          center={  [0 , 0]
            // allPoints && allPoints.length
            //   ? [allPoints[1].lat, allPoints[1].lon]
            //   : [36.061, -81.441]
          }
          zoom = {3}
          style={{
            width: "100%",
            height: "700px",
            zIndex: 5,
          }}
        >
          {/* <ChangeView/> */}
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Street Map">
              <TileLayer url={mapView.osm.url} {...mapView.osm.default} />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Satellite Map">
              <TileLayer url={mapView.osm.url} {...mapView.osm.default} />
            </LayersControl.BaseLayer>
            {allPoints &&
              allPoints.length &&
              allPoints.map((p) => {
                return (
                  <Circle
                    center={[p.lat, p.lon]}
                    pathOptions={{ fillColor: "red" }}
                    radius={200}
                    eventHandlers={{
                      click: (e) => {
                        refetch(p.file_name);
                      },
                    }}
                  >
                    <Popup>{p.title}</Popup>
                  </Circle>
                  // <Marker
                  //   eventHandlers={{
                  //     click: (e) => {
                  //       refetch(p.fname);
                  //     },
                  //   }}
                  //   position={[p.lat, p.lon]}
                  // >
                  //   <Popup>{p.title}</Popup>
                  // </Marker>
                );
              })}
          </LayersControl>
          {points && points.length && (
            <Polyline
              pathOptions={{
                color: "blue",
              }}
              positions={getCordinates(points)}
            />
          )}
        </MapContainer>
      </div>
    );
};
