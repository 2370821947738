import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import ImageGallery from "react-image-gallery";
import { Layout } from "../Layout";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import Button from '@mui/material/Button';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Slide,
  StandardProps,
  Theme,
  Typography,
} from "@material-ui/core";
import { StyleClassKey } from "JS/Typescript";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { ActivityType, Trail } from "JS/Models/General";
import CloseIcon from "@material-ui/icons/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import AppShortcutIcon from '@mui/icons-material/AppShortcut';


import { getName } from "country-list";
import 'react-medium-image-zoom/dist/styles.css'


import {
  activityTypeToString,
  getCordinates,
  getIconsByActivityType,
  getTrailImageSrc,
  getImagePoint,
  normalizeValue,
  normalizeValueNumber,
} from "JS/Helpers/Helpers";
import {
  LayersControl,
  MapContainer,
  Polyline,
  TileLayer,
  Marker,
  Popup
} from "react-leaflet";

import { greenIcon, redIcon, poiIcon, poiIconBlue } from "JS/Models/General";

import { getImageSrc, ImageNames, mapView } from "JS/Helpers/Media";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGeoPoints } from "JS/React/Hooks/Trails";


const useStyles = makeStyles((theme: Theme) => ({

  // root: {
  //   maxHeight: "calc(100vh - 100px)", // Adjust height as needed
  //   overflowY: "auto",
  // },

  root: {
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 60px)",
    maxHeight: "calc(100vh - 60px)",
    background: theme.navbar.background,
    marginTop: theme.navbar.height,
  },
  fontWeightBolder: {
    fontWeight: "bolder",
  },
  actTypeIcon: {
    color: theme.palette.common.black,
    fontSize: "5rem",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "200px",
    margin: "0 auto",
  },

  mapContainer: {
    minHeight: "50vh",
    width: "100%",
  },
  authorContainer: {
    background: theme.palette.grey[400],
    padding: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  marginTopBottom: {
    margin: theme.spacing(1, 0),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  elementsGridContainer: {
    padding: theme.spacing(4),
    margin: 0,
    textAlign: "center",
  },
  elementValue: {
    fontWeight: 600,
  },
  elementContainer: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginBottom: theme.spacing(2),
  },
  nameImage: {
    height: 200,
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, 0),
      width: "100%",
    },
  },

  galleryImageStyle: {
    width: "100%",
    height: 200
  }
}));

export type TrailsDetailModalClassKey = StyleClassKey<typeof useStyles>;

export interface TrailsDetailModalProps
  extends StandardProps<{}, TrailsDetailModalClassKey> {
  open: boolean;
  onClose: () => void;
  trail: Trail;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const TrailsDetailModal = (props: TrailsDetailModalProps) => {
  const classes = useStyles(props);
  const { open, onClose, trail } = props;
  const { points } = useGeoPoints(trail.file_name);
  const [srcErr, setSrcErr] = useState(false);

  const handleDownloadClick = (url) => {
    window.open(url, '_blank');
  };

  const handleDownloadGpx = async (routeLink) => {

    const serverUrl = `https://altimeter-alarm-b4797865fdaf.herokuapp.com/get-gpx?fname=${routeLink}`

    try {
      const response = await fetch(serverUrl, {
        method: 'GET', // Adjust if necessary, e.g., if your server expects a POST request
      });

      if (!response.ok) {
        throw new Error(`Server responded with an error: ${response.statusText}`);
      }

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers.get('Content-Disposition');

      response.headers.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });

      let filename = 'default_filename.gpx'; // Fallback filename if extraction fails
      const filenameRegex = /filename="([^"]+)"/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches && matches[1]) {
        filename = matches[1];
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);

      // Create a temporary anchor to trigger download
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = filename; // Use the extracted filename
      document.body.appendChild(a);
      a.click();

      // Cleanup
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Failed to download the file:', error);
    }
  };


  const wazeLink = points ? `https://www.waze.com/livemap?lat=${points[0]}&lon=${points[1]}&zoom=20` :
    `https://www.waze.com/livemap?lat=${trail.lat}&lon=${trail.lon}&zoom=20`

  // const RouteLink = `https://altlas-app.com/app/?link=https://altlas-app.com/search-trails?trailInfo=${trail.file_name}&apn=altitude.alarm.erol.apps`;
  const RouteLink = `https://altlas-app.com/view-trail?id=${trail.file_name}`;

  // Initialize an array with a default image
  var images = []

  // If trail.imagePoints exist, add additional images to the array
  const hasmain = (trail.imagePoints === undefined || trail.imagePoints && trail.imagePoints[0].imgName.includes("_1"))
  if (trail.imagePoints) {//todo change the andorid code so that trail.imagePoints will always exists and include all images - main and non. but what about backward comp?
    images =
      trail.imagePoints.map((imgPoint, index) => ({
        original: getImagePoint(imgPoint.imgName),
        thumbnail: getImagePoint(imgPoint.imgName),
        description: imgPoint.description,
      }));

  }
  if (hasmain) {
    images.push(
      {
        original: getTrailImageSrc(trail.file_name),
        thumbnail: getTrailImageSrc(trail.file_name),
        description: "ALTLAS APP",
      },
    );
  }

  return (
    <Layout disableFooter>
      <div className={classes.root}>
        <head>
          <Helmet>
            <title>ALTLAS | {trail.title} - Trail Information and Map</title>
            <meta
              name="description"
              content={`Explore details about ${trail.title} ${activityTypeToString(`${trail.actType}` as ActivityType)}. ${trail.description ? trail.description : ''
                }. Discover the trail's location, difficulty, and explore the interactive map on ALTLAS.`}
            />
            <meta
              name="keywords"
              content={`${activityTypeToString(`${trail.actType}` as ActivityType)}, ${trail.title}, ${trail.description ? trail.description : ''}, ${trail.country ? trail.country : ''}, trail map, trail difficulty`}
            />
            <meta name="author" content={trail.author ? trail.author : ''} />
            <meta name="robots" content="index, follow" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="canonical" href={`https://altlas-app.com/trails/${trail.file_name}`} />
          </Helmet>

        </head>
        <Dialog open={open} onClose={onClose} TransitionComponent={Transition} fullScreen>
          <DialogTitle>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">Trail</Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Grid container>
                <Grid item md={2}></Grid>
                <Grid item md={6} xs={12}>
                  <div
                    className={clsx(classes.authorContainer, classes.marginBottom)}
                  >
                    <div>
                      <FontAwesomeIcon
                        className={classes.actTypeIcon}
                        icon={getIconsByActivityType(
                          `${trail.actType}` as ActivityType
                        )}
                        size = "5x"
                      />
                    </div>
                    <div>
                      <div className={classes.marginBottom}>
                        <Typography
                          className={clsx(classes.fontWeightBolder)}
                          color="textPrimary"
                          variant="body2"
                        >
                          {activityTypeToString(`${trail.actType}` as ActivityType)}
                        </Typography>
                        <Typography color="textPrimary" variant="h4">
                          {normalizeValue(trail.title)}
                        </Typography>
                      </div>

                      <div className={classes.marginBottom}>
                        <Typography
                          className={clsx(classes.fontWeightBolder)}
                          color="textPrimary"
                          variant="body2"
                        >
                          Author
                        </Typography>
                        <Typography color="textPrimary" variant="body1">
                          {trail.author ? normalizeValue(trail.author) : "ALTLAS"}
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          className={clsx(classes.fontWeightBolder)}
                          color="textPrimary"
                          variant="body2"
                        >
                          Trip Time
                        </Typography>
                        <Typography color="textPrimary" variant="body1">
                          {normalizeValue(trail.tripTime)}
                        </Typography>
                      </div>
                    </div>
                  </div>

                  <MapContainer
                    center={[trail.lat, trail.lon]}
                    zoom={15}
                    className={classes.mapContainer}
                  >
                    <LayersControl position="topright">
                      <LayersControl.BaseLayer checked name="Street Map">
                        <TileLayer
                          url={mapView.osm.url}
                          {...mapView.osm.default}
                        />
                      </LayersControl.BaseLayer>
                      <LayersControl.BaseLayer name="Satellite Map">
                        <TileLayer
                          url={mapView.mapBox.satellite.urlRaster}
                          {...mapView.mapBox.satellite}
                        />
                      </LayersControl.BaseLayer>
                    </LayersControl>
                    {points && (
                      <Polyline
                        pathOptions={{
                          color: "blue",
                        }}
                        positions={getCordinates(points)}
                      />
                    )}
                    {points && trail && (
                      <Marker position={[points[0], points[1]]} icon={greenIcon}>
                        <Popup>
                          {"Start"}
                        </Popup>
                      </Marker>
                    )}
                    {points && trail && (
                      <Marker position={[trail.lat, trail.lon]} icon={redIcon} >
                        <Popup>
                          {"Finish"}
                        </Popup>
                      </Marker>
                    )}
                    {trail.imagePoints && trail.imagePoints.map((point, index) => (
                      <Marker
                        key={index}
                        position={[point.lat, point.lon]}
                        icon={point.color == "black" ? poiIconBlue : poiIcon}
                      >
                        <Popup>{point.description}</Popup>
                      </Marker>
                    ))}
                  </MapContainer>

                  <div
                    className={clsx(classes.imageContainer, classes.marginTopBottom)}
                  >
                    {images.length && <Typography variant="h5">Trail Snapshots</Typography> &&
                      <ImageGallery items={images} additionalClass={classes.galleryImageStyle} autoPlay={true} showBullets={true} onErrorImageURL={getImageSrc(ImageNames.DUMMY_IMAGE)}

                        onError={() => {
                          setSrcErr(true)
                        }} />}
                  </div>


                </Grid>
                <Grid container item md={3} xs={6}>

                  <Grid item xs={2} className={classes.elementsGridContainer}>
                    <div className={classes.elementContainer}>
                      <Typography variant="body2">Country</Typography>
                      <Typography
                        className={classes.elementValue}
                        color="textPrimary"
                        variant="body1"
                      >
                        {trail.country_code ? normalizeValue(getName(trail.country_code)) : "Unknown"}
                      </Typography>
                    </div>
                    <div className={classes.elementContainer}>
                      <Typography variant="body2">City</Typography>
                      <Typography
                        className={classes.elementValue}
                        color="textPrimary"
                        variant="body1"
                      >
                        {trail.city ? normalizeValue(trail.city) : "ALTLAS"}
                      </Typography>
                    </div>
                    <div className={classes.elementContainer}>
                      <Typography variant="body2">Distance</Typography>
                      <Typography
                        className={classes.elementValue}
                        color="textPrimary"
                        variant="body1"
                      >
                        {normalizeValueNumber(trail.gDistanceMetric)}km
                      </Typography>
                    </div>
                  </Grid>

                  <Grid item xs={3} className={classes.elementsGridContainer}>
                    <div className={classes.elementContainer}>
                      <Typography variant="body2">Altitude</Typography>
                      <Typography
                        className={classes.elementValue}
                        color="textPrimary"
                        variant="body1"
                      >
                        {normalizeValue(trail.alt)}
                      </Typography>
                    </div>
                    <div className={classes.elementContainer}>
                      <Typography variant="body2">Elevation</Typography>
                      <Typography
                        className={classes.elementValue}
                        color="textPrimary"
                        variant="body1"
                      >
                        {normalizeValue(trail.elev)}
                      </Typography>
                    </div>
                    <div className={classes.elementContainer}>
                      <Typography variant="body2">Duration</Typography>
                      <Typography
                        className={classes.elementValue}
                        color="textPrimary"
                        variant="body1"
                      >
                        {normalizeValue(trail.duration)}
                      </Typography>
                    </div>
                    {trail.description && (
                      <div className={classes.elementContainer}>
                        <Typography
                          className={classes.elementValue}
                          color="textPrimary"
                          variant="h6"
                        >
                          {normalizeValue(trail.description)}
                        </Typography>
                      </div>)}


                  </Grid>

                  <Grid item xs={12} className={classes.elementsGridContainer}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                      <div className={classes.elementContainer}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDownloadGpx(trail.file_name)}
                          style={{ borderRadius: 20, fontSize: '1rem' , textTransform: 'none'}}
                          startIcon={<FileDownloadIcon />}
                        >
                          Download GPX
                        </Button>
                      </div>


                      <div className={classes.elementContainer}>

                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleDownloadClick(RouteLink)}
                          style={{ borderRadius: 20, fontSize: '1rem' , textTransform: 'none'}}
                          sx={{ borderRadius: 20, fontSize: '1rem', marginTop: '20px' }} // Adjust padding and margin as needed

                          startIcon={<AppShortcutIcon />}
                        >
                          Show in the app
                        </Button>
                      </div>

                      <div className={classes.elementContainer}>


                        <a
                          target="_blank"
                          href={`https://play.google.com/store/apps/details?id=altitude.alarm.erol.apps`}
                        >
                          <img
                            alt="download the Android App"
                            src={getImageSrc(ImageNames.GOOGLE_PLAY_BADGE)}
                            height="80"
                          />
                        </a>
                        <a
                          target="_blank"
                          href={wazeLink}
                        >
                          <img
                            src={getImageSrc(ImageNames.WAZE_BADGE)}
                            height="80"
                          />
                        </a>



                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

            </DialogContentText>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </div>
    </Layout>
  );
};
