import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useRouting } from "../Hooks/Routes";
import { LandingPage } from "./LandingPage/LandingPage";
import { SearchTrails } from "./SearchTrails/SearchTrails";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useLocation } from 'react-router-dom';
import { useOneTrail } from "JS/React/Hooks/Trails";
import { TrailsDetailModal } from "./SearchTrails/TrailDetailModal";

import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faBinoculars,
  faCompactDisc,
  faSearchLocation,
  faLayerGroup,
  faMapMarkedAlt,
  faMountain,
  faTachometerAlt,
  faLongArrowAltUp,
  faShareAlt,
  faUsers,
  faCog,
  faComments,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faHiking,
  faRunning,
  faBiking,
  faSkating,
  faShip,
  faCar,
  faMotorcycle,
  faWalking,
  faDog,
} from "@fortawesome/free-solid-svg-icons";
import { faCompass } from "@fortawesome/free-regular-svg-icons";
import {
  faGooglePlay,
  faFacebookF,
  faTwitter,
  faPinterestP,
  faInstagram,
  faTelegram,
  faFly,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faBars,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faBinoculars,
  faCompactDisc,
  faSearchLocation,
  faLayerGroup,
  faMapMarkedAlt,
  faMountain,
  faTachometerAlt,
  faLongArrowAltUp,
  faCompass,
  faShareAlt,
  faUsers,
  faCog,
  faComments,
  faGooglePlay,
  faFacebookF,
  faTelegram,
  faTwitter,
  faPinterestP,
  faInstagram,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faHiking,
  faRunning,
  faBiking,
  faSkating,
  faShip,
  faCar,
  faMotorcycle,
  faWalking,
  faDog,
  faFly
);

const Root = () => {
  const { routeBuilder } = useRouting();
  const routeProvider = routeBuilder();
  const location = useLocation();
  const [, setDialogOpen] = useState<boolean>(false);
  const { loading: urlTrailLoading, response: trailRes } = useOneTrail((location.pathname.split("/trails/")[1] != undefined? location.pathname.split("/trails/")[1].replaceAll('+',' '):""));
  // _ref2.match.params.id
  return (
    <React.Fragment>
      {/* <AppNavbar /> */}
      <Switch>
        <Route
          exact={true}
          path={routeProvider.react.root()}
          render={() => {
            return <LandingPage />;
          }}
        />
        {/* <Router> */}
        <Route
          exact={true}
          path={routeProvider.react.searchTrails()}
          render={({ location, history }) => {
            return <SearchTrails location={location} history={history} />;
          }}
        />
        {/* <Router> */}
        
        <Route
          exact={true}
          path={routeProvider.react.trails()}
          render={({ location, history}) => {
            // const { loading: urlTrailLoading, response: trailRes } = useOneTrail((location.pathname.split("/trails/")[1].replaceAll('+',' ')));
           return(
            !urlTrailLoading && trailRes &&
            <TrailsDetailModal 
            open={trailRes!= null}
            onClose={() => setDialogOpen(true)}
            trail={trailRes}
          />
           )  
          }}
        />
        {/* </Router> */}
        <Route
          exact={true}
          path={routeProvider.react.share()}
          render={() => {
            return <LandingPage />;
          }}
        />
      </Switch>
      {/* <Footer /> */}
    </React.Fragment>
  );
};

export default Root;
